<template>
  <!-- <layout :name="layout"> -->
    <div class="Home" v-if="$store.getters.isLoggedIn ">
      <organiz-header
               v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN' ||
                currentUser.authorities[0].authority === 'ROLE_PROFESSOR'
              || currentUser.authorities[0].authority === 'ROLE_PROCTOR'"/>
      <student-header v-show="currentUser.authorities[0].authority === 'ROLE_USER'"/>
      <section class="uk-section uk-section-small uk-padding-remove-top ">
        <div class="uk-container">
          <div class="uk-text-center uk-margin">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li>
                <router-link title="student" tag="a" :to="{name: 'student'}"> {{$t("globViews.history.home")}}
                </router-link>
              </li>
              <li>
                <span>{{$t("globViews.history.history")}}</span>
              </li>
            </ul>
            <!--<h1 class="uk-margin-small-top uk-margin-remove-bottom">Student register</h1>-->
          </div>

          <div class="uk-width-1-1">
            <div v-if="currentUser.passType === 0">
              <div class="uk-alert-warning" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                <p>{{$t("globViews.history.pChange")}}
                  <a href="#modal-passwordchange" uk-toggle>
                    {{$t("globViews.history.cMe")}}</a></p>
              </div>
            </div>
          </div>

          <div class="uk-grid-medium" uk-grid>
            <div class="uk-width-1-1 uk-width-1-4@m tm-aside-column">
              <app-my-navmenu
              ></app-my-navmenu>
            </div>
            <div class="uk-width-1-1 uk-width-expand@m">
              <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                <header class="uk-card-header"><h1 class="uk-h2">{{$t("globViews.history.purHistory")}}</h1></header>
                <section class="uk-card-body uk-margin-right uk-margin-left">
                  <div class="uk-grid uk-grid-small uk-margin-medium " uk-margin>
                    <div class="uk-inline">
                      <input v-model="sdate" class="uk-input" type="date"/>
<!--                      <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>-->
                    </div>
                    <div class="uk-inline">
                      <input v-model="edate" class="uk-input" type="date"/>
<!--                      <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>-->
                    </div>
                    <div>
                      <button type="button" @click="searchDate" class="uk-button uk-button-default">{{$t("globViews.history.search")}}</button>
                    </div>
                  </div>
                  <div class="uk-grid-collapse tm-products-list" uk-grid>
                    <div style="width: 100%" v-if="orderhistories.length > 0">
                      <section v-for="(item, ind) in orderhistories" :key="ind">
                        <div class="uk-grid">
                          <div class="uk-text-large uk-width-1-1 uk-text-bold">
                            <span class="uk-text-emphasis">{{item.orderDate | moment}} -</span>
<!--                            <span class="uk-text-success">{{item.totalpoint | currency}} Out of point purchase</span>-->
                            <span class="uk-text-success">{{item.totalpoint}} Out of point purchase</span>

                          </div>
                          <div class="uk-width-auto uk-margin-remove-top">
                            <div class="uk-text-small">
                              <span>12 total (7 photos, 3 audios)</span>
                            </div>
                          </div>
                          <div class="uk-width-expand uk-text-right uk-text-small">
                            <span>{{$t("globViews.history.search")}}</span>
                          </div>
                        </div>
                        <article v-for="(subitem, indexs) in item.details"
                                 :key="indexs" class="tm-product-card uk-first-column">
                          <div class="tm-product-card-media">
                            <div class="tm-ratio tm-ratio-4-3"><a class="tm-media-box" href="product.html">
                              <div class="tm-product-card-labels"><span
                                class="uk-label uk-label-warning">processing</span></div>
                              <figure class="tm-media-box-wrap" style="overflow:hidden">
                                <vue-plyr
                                  data-width="500"
                                  data-height="300"
                                  v-if="subitem.product.datatype == 2"
                                >
                                  <video poster :src="subitem.product.imageurl">
                                    <source
                                      :src="subitem.product.imageurl"
                                      type="video/mp4"
                                      size="720"
                                    >
                                    <source
                                      :src="subitem.product.imageurl"
                                      type="video/mp4"
                                      size="1080"
                                    >
                                    <track
                                      kind="captions"
                                      label="English"
                                      srclang="ko"
                                      default
                                    >
                                  </video>
                                </vue-plyr>
                                <vue-plyr
                                  data-width="500"
                                  data-height="300"
                                  v-else-if="subitem.product.datatype == 3"
                                >
                                  <audio>
                                    <source
                                      :src="subitem.product.imageurl"
                                      type="audio/mp3"
                                    >
                                    <source
                                      :src="subitem.product.imageurl"
                                      type="audio/ogg"
                                    >
                                  </audio>
                                </vue-plyr>
                                <div class="uk-width-1-1">
                                  <img

                                    class="uk-lazy uk-position-center"
                                    :data-src="subitem.product.imageurl"
                                    style="width:auto"
                                    data-uk-img
                                    alt
                                  >
                                </div>
                              </figure>
                            </a></div>
                          </div>
                          <div class="tm-product-card-body">
                            <div class="tm-product-card-info">
                              <div class="uk-text-meta uk-margin-xsmall-bottom">
                                {{tags[subitem.product.datatype-1].tag}}
                              </div>
                              <h3 class="tm-product-card-title"><a class="uk-link-heading"
                                                                   href="product.html">{{subitem.product.title}}</a>
                              </h3>
                              <!--<ul class="uk-list uk-text-small tm-product-card-properties">-->
                              <!--<li><span class="uk-text-muted">Diagonal display: </span><span>15.4"</span>-->
                              <!--</li>-->
                              <!--<li><span-->
                              <!--class="uk-text-muted">CPU: </span><span>Intel®&nbsp;Core™ i7</span>-->
                              <!--</li>-->
                              <!--<li><span class="uk-text-muted">RAM: </span><span>16&nbsp;GB</span></li>-->
                              <!--<li><span-->
                              <!--class="uk-text-muted">Video Card: </span><span>AMD Radeon Pro 555</span>-->
                              <!--</li>-->
                              <!--</ul>-->
                            </div>
                            <div class="tm-product-card-shop">
                              <div class="tm-product-card-prices">
<!--                                <del class="uk-text-meta">P {{subitem.product.price | currency}}</del>-->
<!--                                <div class="tm-product-card-price">P {{subitem.product.price | currency}}</div>-->
                                <del class="uk-text-meta">P {{subitem.product.price}}</del>
                                <div class="tm-product-card-price">P {{subitem.product.price}}</div>
                              </div>
                              <!--<div class="tm-product-card-add">-->
                              <!--<div class="uk-text-meta tm-product-card-actions"><a-->
                              <!--class="tm-product-card-action js-add-to js-add-to-favorites tm-action-button-active js-added-to"-->
                              <!--title="Add to favorites"><span-->
                              <!--uk-icon="icon: heart; ratio: .75;"></span><span-->
                              <!--class="tm-product-card-action-text">Add to favorites</span></a><a-->
                              <!--class="tm-product-card-action js-add-to js-add-to-compare tm-action-button-active js-added-to"-->
                              <!--title="Add to compare"><span-->
                              <!--uk-icon="icon: copy; ratio: .75;"></span><span-->
                              <!--class="tm-product-card-action-text">Add to compare</span></a></div>-->
                              <!--<button-->
                              <!--class="uk-button uk-button-primary tm-product-card-add-button tm-shine js-add-to-cart">-->
                              <!--<span class="tm-product-card-add-button-icon"-->
                              <!--uk-icon="cart"></span><span-->
                              <!--class="tm-product-card-add-button-text">add to cart</span></button>-->
                              <!--</div>-->
                            </div>
                          </div>
                        </article>
                      </section>
                    </div>
                    <div class="uk-width-1-1" v-else>
                      <div class="uk-alert-primary" uk-alert>
                        <a class="uk-alert-close" uk-close></a>
                        <p class="uk-text-center">{{$t("globViews.history.emp")}}.</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <organiz-footer/>
    </div>

</template>


<script>
  import UserService from '@/services/UserService'
  import {mapGetters} from 'vuex'
  import moment from 'moment'
  import MyNav from '@/components/GlobViews/MyNavmenu'
  import OrganizHeader from '@/components/Organizcmp/OrganizHeader.vue'
  import OrganizFooter from '@/components/Organizcmp/OrganizFooter.vue'
  import StudentHeader from '@/components/Studentcmp/StudentHeaderNew.vue'
  export default {
    name: 'Home',
    components: {
      appMyNavmenu: MyNav,
      OrganizHeader,
      OrganizFooter,
      StudentHeader,
    },

    data() {
      return {
        layout: 'OrganizLayout',
        orderhistories: [],
        datas: [],
        questions: [],
        organizs: [],
        tags: [
          {tag: 'image'},
          {tag: 'video'},
          {tag: 'audio'},
          {tag: 'stack'}
        ],
        iconpoint: require('@/assets/icons/point.png'),
        iconcomment: require('@/assets/icons/comment.png'),
        sdate: null,
        edate: null,
        params: {}
      }
    }
    ,
    computed: {
      ...mapGetters(['isLoggedIn', 'currentUser'])
    },
    created() {
      if (this.currentUser.authorities[0].authority === 'ROLE_USER'
      ) {
        this.layout = "StudentLayout";
      } else {
        this.layout = "OrganizLayout";
      }
      //alert(this.layout)
    }
    ,
    async mounted() {
      this.loadBuys()
    }
    ,
    filters: {
      moment: function (date) {
        return moment(date).format('YYYY.MM.DD')
        // return moment(date).format('YYYY-MM-DD, h:mm:ss a')
      }
    }
    ,
    methods: {
      searchDate() {
        this.loadBuys()
      }
      ,
      loadBuys() {
        if (this.isLoggedIn) {
          this.params = {}
          if (this.sdate) {
            this.params.sdate = this.sdate
          }
          if (this.edate) {
            this.params.edate = this.edate
          }
          // console.log(this.params.edate + '  ffffffffffffffff')
          UserService.getOrders(this.params).then(res => {
            const {data} = res
            this.orderhistories = data
            if (!this.orderhistories.length) {
              return
            }
            this.organizs = []
            this.datas = []
            this.questions = []
            data.forEach(item => {
              item.details.forEach(detail => {
                // let exists = this.organizs.some(function (field) {
                //   return field.name === detail.product.organiz.name
                // })
                // if (!exists) {
                //   this.organizs.push(detail.product.organiz)
                // }
                if (detail.productType === 'data') {
                  this.datas.push(detail.product)
                }
                if (detail.productType === 'question') {
                  this.questions.push(detail.question)
                }
              })
            })
          })
        }
      }
    }
  }
</script>

<style scoped>
</style>
